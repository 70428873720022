import { memo } from 'react';

import ButtonLink from '@esg/shared/components/ButtonLink/ButtonLink';

interface PromoIntroProps {
  text: string;
  actionText?: string;
  actionLink?: string;
}
function PromoIntro({ text, actionText, actionLink }: PromoIntroProps) {
  return (
    <div className="flex flex-col md:flex-row gap-6 py-4 justify-between">
      <div className="text-body-text-large">{text}</div>
      {actionLink && actionText && (
        <ButtonLink href={actionLink}>{actionText}</ButtonLink>
      )}
    </div>
  );
}

export default memo(PromoIntro);

/**
 * These colors definition servers as an interface between tailwind and figma's project
 */
module.exports = {
  primary: {
    dark: '#0F0A25',
    default: '#5037B5',
  },

  secondary: '#595470',

  // state
  selected: '#F4F2FD',
  transparent: 'transparent',

  // status
  error: '#E22222',
  alert: '#8B602D',
  success: '#158463',

  // gray scale
  gray: {
    medium: '#CEC9D8',
    light: '#E7E5EC',
  },

  semantic: {
    teal: '#EBFFFF',
    green: '#EBFFF0',
    fuchsia: '#FFEBF8',
    red: '#FFEBEB',
    purple: '#EFEBFF',
    blue: '#EBF8FF',
    yellow: '#F6F6DC',

    dark: {
      teal: '#32A1A1',
      green: '#4CAD65',
      fuchsia: '#BD5097',
      red: '#CA4747',
      purple: '#6F58C8',
      blue: '#3B90BD',
      yellow: '#A5A840',
      pink: '#d80084',
    },
  },

  white: '#FFFFFF',
};

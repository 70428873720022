import React from 'react';

interface EsgDividerProps {
  customClass?: string;
}

const EsgDivider: React.FC<EsgDividerProps> = ({
  customClass = '',
}: EsgDividerProps) => {
  return <div className={`h-px w-auto bg-monochrome-1 ${customClass}`}></div>;
};

export default EsgDivider;

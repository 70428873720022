import i18next from 'i18next';
import Link from 'next/link';

import Button from '../Button/Button';
import { ButtonProps, ButtonVariants } from '../Button/button-types';

function ButtonLink({
  variant = ButtonVariants.PRIMARY,
  color = 'primary',
  href = '',
  prefixLang = true,
  replace = false,
  ...props
}: ButtonProps & { href: string; prefixLang?: boolean }) {
  const { language } = i18next;

  return (
    <Link
      href={prefixLang ? `/${language}${href}` : href}
      {...(replace ? { replace: true } : {})}
    >
      <Button {...props} variant={variant} color={color}>
        {props.children}
      </Button>
    </Link>
  );
}

export default ButtonLink;

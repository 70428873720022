export const languages = ['en', 'pl'];

export const defaultLanguage = 'en';

export const namespaces = [
  'common',
  'meta',
  'error',
  'dashboard',
  'app-nav-bar',
  'app-header',
  'global',
  'notification-menu',
  'analytics',
  'chat',
  'conditional-container',
  'user-profile-menu',
  'program',
  'period-indicator',
  'report-table',
  'report-filter',
  'aside',
  'select-form',
  'calender-form',
  'disclosure',
  'new-assessment',
  'reports',
  'report-details',
  'create-program',
  'new-strategy',
  'new-report',
  'my-sustainability',
  'my-organization',
  'new-suborganization',
  'disclosure-esrs-s1-9',
  'table-search-filter',
  'manual-disclosure-selection',
  'user-profile',
  'materiality-assessment',
  'connected-goals',
  'materiality-topics',
  'disclosure-fictional-fict1',
  'data-management',
  'new-data-collection',
  'framework-info-card',
  'period-selector',
  'create-data-collection',
  'connected-reports',
  'disclosures-info',
  'data-providers',
  'widgets',
  'team-members',
  'todo',
  'strategy',
  'promo-strategy',
  'promo-my-sustainability',
  'promo-my-organization',
  'promo-materiality-assessment',
  'promo-analytics',
  'promo-data-management',
  'promo-audit-log',
  'team-member-roles',
  'company-information',
  'public-global',
  'public-company-onboarding',
  'assign-todo',
  'disclosure-info-details',
];

export const defaultNamespace = 'common';

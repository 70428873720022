'use client';

import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import {
  MdHelpOutline,
  MdOutlineChat,
  MdOutlineHome,
  MdOutlineMinimize,
} from 'react-icons/md';

const navKeys = {
  home: 'Home',
  aiAssistant: 'AI Assistant',
  help: 'Help',
};
function ChatBot() {
  const [chatbotVisible, setChatbotVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState(navKeys.home);
  const chatbotId = process.env.NEXT_PUBLIC_CHATBOT_ID;

  useEffect(() => {
    if (!chatbotId) {
      console.error('chatbotId is not defined.');
    }
  }, []);

  const toggleChatbot = () => {
    setChatbotVisible((prevVisible) => !prevVisible);
  };

  function handleTabClick(name = '') {
    setCurrentTab(name);
  }

  const renderIframe = (name = '') => {
    switch (name) {
      case navKeys.home:
        return process.env.NEXT_PUBLIC_CHATBOT_NAVIGATION_HOME;
      case navKeys.aiAssistant:
        return process.env.NEXT_PUBLIC_CHATBOT_NAVIGATION_AiASSISTANT;
      case navKeys.help:
        return process.env.NEXT_PUBLIC_CHATBOT_NAVIGATION_HELP;
      default:
        break;
    }
  };

  const navigationItems = [
    { name: navKeys.home, icon: <MdOutlineHome size={24} /> },
    { name: navKeys.aiAssistant, icon: <MdOutlineChat size={24} /> },
    { name: navKeys.help, icon: <MdHelpOutline size={24} /> },
  ];

  const renderBottomNavigation = () => {
    return (
      <ul className="flex justify-around border border-r-0 border-l-0 border-b-0 border-monochrome-1 bg-background pt-1.5 pb-2.5 px-4 rounded-b-2xl">
        {navigationItems.map((item) => (
          <li
            key={item.name}
            className={`flex flex-col min-w-[6.438rem] items-center pt-2.5 pb-1.5 px-1.5 rounded-lg cursor-pointer ${
              currentTab === item.name
                ? 'text-primary bg-selected'
                : 'text-secondary'
            }  `}
            onClick={() => handleTabClick(item.name)}
          >
            <div>{item.icon}</div>
            <div className="text-caption font-bold">{item.name}</div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div
      className={`fixed bottom-8 ${
        chatbotVisible ? 'right-6 min-w-[25rem]' : 'right-10 min-w-[20rem]'
      } z-[10000] bg-supportive-2 border border-monochrome-1 rounded-2xl`}
    >
      <button
        className="flex flex-row items-center relative w-full"
        onClick={toggleChatbot}
      >
        <Image
          src={'/images/chat-bot/aihelp.svg'}
          alt={'ai help'}
          width={65}
          height={61}
        />
        <div className="text-caption font-bold text-secondary w-[12.5rem]">
          Get the right help, the right time from our AI Assistant
        </div>
        {chatbotVisible ? (
          <div className="mr-5 ml-20">
            <MdOutlineMinimize size={24} className="mb-3.5" />
          </div>
        ) : (
          <Image
            className="absolute w-auto h-[140%] right-[-2.188rem] top-[-0.313rem]"
            src={'/images/chat-bot/messanger.svg'}
            alt={'messanger'}
            width={60}
            height={60}
          />
        )}
      </button>
      {chatbotVisible && (
        <div className="w-auto h-[34.375rem] bg-background">
          <iframe
            src={renderIframe(currentTab)}
            className="w-full h-full border-none "
          ></iframe>
        </div>
      )}
      {chatbotVisible && renderBottomNavigation()}
    </div>
  );
}

export default ChatBot;

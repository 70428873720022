import { ReactNode } from 'react';

function PromoContent(props: { children: ReactNode }) {
  return (
    <article className="py-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4">
      {props.children}
    </article>
  );
}

export default PromoContent;

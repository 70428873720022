/* eslint-disable no-unused-vars */

export enum DataCollectionStatus {
  UNSTRUCTURE = 'UNSTRUCTURE',
  SUBMITTED = 'SUBMITTED',
  IN_PROGRESS = 'IN_PROGRESS',
  REQUESTED = 'REQUESTED',
  STRUCTURING = 'STRUCTURING',
  STRUCTURED = 'STRUCTURED',
}

export enum DataCollectionPkgStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  SENT = 'SENT',
}

export enum CollectionFrequency {
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export enum DisclosureStructureSaveStatus {
  DRAFT = 'DRAFT',
  FINAL = 'FINAL',
}

export enum DisclosureDataSaveStatus {
  DRAFT = 'DRAFT',
  FINAL = 'FINAL',
}

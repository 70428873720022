/* eslint-disable @typescript-eslint/no-misused-promises */
import { useLazyQuery } from '@apollo/client';
import { FbSelect } from '@fb/ui-kit';
import { IAudit } from '@fb-ui/shared-types';
import { useEffect, useMemo, useRef, useState } from 'react';

import {
  GET_AUDIT_LOGS_QUERY,
  IGetAuditLogsDto,
} from '@esg/graphql/audit-log/getAuditLogs.query';
import { useTranslation } from '@esg/i18n';
import { ErrorApiHandler } from '@esg/shared/components/ErrorApiHandler/ErrorApiHandler';
import EsgDivider from '@esg/shared/components/esg-divider/EsgDivider';
import { FbSearch } from '@esg/shared/components/Search/Search';
import useAuth from '@esg/shared/hooks/useAuth';
import { useAuditLogStore } from '@esg/shared/store/slices/auditLogState';
import { useRouteSearch } from '@esg/shared/hooks/useRouteSearch';

import ActivityItem from './ActivityItem/ActivityItem';
import AsideSkeleton from './AsideSkeleton/AsideSkeleton';
import {
  AuditLogResource as AuditLogDomain,
  IContributor,
} from './audit-log.types';
import { useTableHelpers } from '@esg/shared/hooks';

function AuditLogView({
  isOpen,
}: {
  isOpen: boolean;
  openFunc: (_isOpen: boolean) => void;
}) {
  const { t } = useTranslation('aside');

  const resources: AuditLogDomain[] = [
    {
      label: t('resources.all'),
      value: '',
    },
    {
      label: t('resources.company'),
      value: 'company',
    },
    {
      label: t('resources.program'),
      value: 'program',
    },
    {
      label: t('resources.materialityAssessment'),
      value: 'materiality-assessment',
    },
    {
      label: t('resources.dataManagement'),
      value: 'data-collection',
    },
  ];

  const contributor: IContributor[] = [
    {
      label: t('contributor.allContributor'),
      value: 'all-contributor',
    },
    {
      label: t('contributor.you'),
      value: 'you',
    },
  ];
  const [auditLogs, setAuditLogs] = useState<IAudit[]>([]);
  const [firstLogs, setFirstLogs] = useState<IAudit[]>([]);
  const [visibleLogs, setVisibleLogs] = useState<IAudit[]>([]);
  const [selectedResource, setSelectedResorce] = useState<string>('');
  const [selectedContributor, setSelectedContributor] = useState<string>('');

  const logsContext = useAuditLogStore((state) => state.context);

  const { searchTerm, updateSearchTerm } = useRouteSearch();

  const { user } = useAuth();
  const profile = user?.profile;

  const [
    getAuditLog,
    { data: auditData, loading: auditLoading, error: auditError, fetchMore },
  ] = useLazyQuery<IGetAuditLogsDto>(GET_AUDIT_LOGS_QUERY, {
    variables: {
      resourceName: selectedResource === '' ? '' : selectedResource,
      size: 100,
      startIndex: 0,
      companyId: selectedResource === '' ? profile.companyId : '',
      userId: selectedContributor === 'all-contributor' ? '' : profile.sub,
    },
    pollInterval: 30000,
  });

  const resourceChange = useMemo(() => {
    return (value: string) => {
      setSelectedResorce(value);
    };
  }, []);

  const contributorChange = useMemo(() => {
    return (value: string) => {
      setSelectedContributor(value);
    };
  }, []);

  useEffect(() => {
    if (auditData?.getAuditLogs) {
      setAuditLogs(auditData.getAuditLogs);
      setFirstLogs(auditData.getAuditLogs.slice(0, 5));
    }
  }, [auditData]);

  useEffect(() => {
    if (logsContext || profile.companyId) {
      setSelectedResorce(logsContext === 'all' ? '' : logsContext);
      setSelectedContributor('all-contributor');
      void getAuditLog();
    }
  }, [logsContext, getAuditLog, profile.companyId]);

  useEffect(() => {
    if (isOpen) {
      setVisibleLogs(auditLogs);
    } else {
      setVisibleLogs(firstLogs);
    }
  }, [isOpen, firstLogs, auditLogs]);

  const auditRef = useRef<HTMLDivElement>(null);

  const handleScroll = async () => {
    const { scrollTop, clientHeight, scrollHeight } = auditRef.current!;
    if (scrollTop + clientHeight >= scrollHeight - 600 && !auditLoading) {
      await fetchMore({
        variables: {
          resourceName: selectedResource === '' ? '' : selectedResource,
          size: 100,
          startIndex: visibleLogs.length,
          companyId: selectedResource === '' ? profile.companyId : '',
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          if (Array.isArray(prev.getAuditLogs)) {
            return {
              ...prev,
              getAuditLogs: [
                ...prev.getAuditLogs,
                ...fetchMoreResult.getAuditLogs,
              ],
            };
          } else {
            return {
              ...prev,
              getAuditLogs: fetchMoreResult.getAuditLogs || [],
            };
          }
        },
      });
    }
  };

  useEffect(() => {
    const auditElement = auditRef.current;
    if (auditElement) {
      auditElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (auditElement) {
        auditElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [visibleLogs]);

  const { filteredData } = useTableHelpers({ data: visibleLogs });

  return (
    <section>
      <div className="my-2.5 flex">
        <FbSelect
          className="flex-grow"
          placeholder={t('allActivity')}
          data={resources}
          value={selectedResource}
          onChange={resourceChange}
        />
        {isOpen && (
          <>
            <FbSelect
              className="flex-grow px-3"
              placeholder={t('allContributor')}
              data={contributor}
              value={selectedContributor}
              onChange={contributorChange}
            />
            <FbSearch
              placeholder={t('searchActivity')}
              className="flex-grow"
              value={searchTerm}
              onChange={(e) => updateSearchTerm(e.target.value)}
            />
          </>
        )}
      </div>

      <div className="h-[calc(100vh-15.313rem)] overflow-auto" ref={auditRef}>
        {!auditLoading && !auditError && filteredData && (
          <div className="flex flex-col">
            {(filteredData || []).map((auditLog, index) => {
              return (
                <div key={`${auditLog.id}-${index}`}>
                  {index > 0 && <EsgDivider />}

                  <ActivityItem
                    actionText={t('preview')}
                    activity={{
                      id: auditLog.id,
                      image: '',
                      initials: 'NA',
                      description: auditLog.message,
                      showPreview: false,
                      date: new Date(auditLog.date),
                    }}
                  />
                </div>
              );
            })}
            {filteredData.length == 0 && (
              <p className="text-center p-5">No data found</p>
            )}
          </div>
        )}

        {auditLoading && <AsideSkeleton />}

        <ErrorApiHandler error={auditError} isTransparent={true} />
      </div>
    </section>
  );
}

export default AuditLogView;

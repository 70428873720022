/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

import {
  AnalyticsPermissions,
  CompanyProfilePermissions,
  DataCollectionPermissions,
  DashboardPermissions,
  MaterialityAssessmentPermissions,
  PlatformAdminPermissions,
  ProgramPermissions,
  ReportPermissions,
  StrategyPermissions,
  UserProfilePermissions,
  UserRole,
  AppPermission,
  BillingPermissions,
  EcosystemKYCPermissions,
  NotificationPermissions,
  SupportedFramework,
} from '@fb-ui/shared-types';

const COMMON_DASHBOARD_WIDGETS = [
  DashboardPermissions.WIDGET_CONFIGURE_OWN_COMPANY_PROFILE_INTRO,
  DashboardPermissions.WIDGET_OWN_CONNECTED_COMPANY,
  DashboardPermissions.WIDGET_OWN_TEAM_MEMBERS,
  DashboardPermissions.WIDGET_CERTIFICATION_INTRO,
];

const MATERIALITY_ASSESSMENT_WIDGET = [
  DashboardPermissions.WIDGET_OWN_CONNECTED_ASSESSMENT,
];

const PROGRAM_WIDGET = [
  DashboardPermissions.WIDGET_OWN_PROGRAMS_FINISHED_COUNTER,
  DashboardPermissions.WIDGET_OWN_PROGRAMS_ONGOING_COUNTER,
];

const ALL_ROLES_PERMISSIONS = [
  NotificationPermissions.READ,
  SupportedFramework.ESRS,
];

export const ROLES_TO_PERMISSIONS_MAPPING: Record<UserRole, AppPermission[]> = {
  [UserRole.COMPANY_ADMIN]: [
    CompanyProfilePermissions.ADD_USERS_TO_COMPANY,
    CompanyProfilePermissions.ADD_SUBORGANIZATIONS,
    CompanyProfilePermissions.ADD_SUPPLIERS,
    CompanyProfilePermissions.READ_AUDIT_LOGS,
    CompanyProfilePermissions.ADD_USERS_TO_USER_GROUP,
    CompanyProfilePermissions.REMOVE_USERS_FROM_USER_GROUP,
    CompanyProfilePermissions.EDIT_KYC_INFORMATION,
    CompanyProfilePermissions.EDIT_PROFILE,
    CompanyProfilePermissions.EDIT_DATA_SHARING_AGREEMENT,
    ProgramPermissions.READ,
    DataCollectionPermissions.READ,
    ReportPermissions.READ,
    MaterialityAssessmentPermissions.READ,
    AnalyticsPermissions.READ_ADMIN_ANALYTICS,
    AnalyticsPermissions.READ_USER_ANALYTICS,
    UserProfilePermissions.CREATE,
    UserProfilePermissions.READ,
    UserProfilePermissions.ACCEPT_UPDATE,
    UserProfilePermissions.DELETE,
    DashboardPermissions.READ,
    BillingPermissions.read,
    EcosystemKYCPermissions.ADD_TO_ORGANIZATION,
    EcosystemKYCPermissions.ADD_TO_VALUE_CHAIN,
    ...COMMON_DASHBOARD_WIDGETS,
    ...PROGRAM_WIDGET,
    ...MATERIALITY_ASSESSMENT_WIDGET,
    ...ALL_ROLES_PERMISSIONS,
  ],
  [UserRole.COMPANY_PROGRAM_OWNER]: [
    ProgramPermissions.CREATE,
    ProgramPermissions.READ,
    ProgramPermissions.UPDATE,
    ProgramPermissions.TERMINATE,
    DataCollectionPermissions.READ,
    ReportPermissions.READ,
    MaterialityAssessmentPermissions.READ,
    UserProfilePermissions.READ,
    DashboardPermissions.READ,
    ...COMMON_DASHBOARD_WIDGETS,
    ...MATERIALITY_ASSESSMENT_WIDGET,
    ...PROGRAM_WIDGET,
    ...ALL_ROLES_PERMISSIONS,
  ],
  [UserRole.COMPANY_DATA_MANAGEMENT_COORDINATOR]: [
    DataCollectionPermissions.SEND_REQUEST_TO_DISCLOSURE_OWNER,
    DataCollectionPermissions.READ,
    ReportPermissions.READ,
    UserProfilePermissions.READ,
    MaterialityAssessmentPermissions.READ,
    DashboardPermissions.READ,
    ...COMMON_DASHBOARD_WIDGETS,
    ...MATERIALITY_ASSESSMENT_WIDGET,
    ...ALL_ROLES_PERMISSIONS,
  ],
  [UserRole.COMPANY_REPORT_OWNER]: [
    ReportPermissions.CREATE,
    ReportPermissions.READ,
    ReportPermissions.ADD_DISCLOSURES,
    ReportPermissions.SEND_REQUEST_TO_DISCLOSURE_OWNER,
    ReportPermissions.SUBMIT,
    ReportPermissions.EDIT_IXBRL,
    ReportPermissions.REPORT_CONSUMPTION,
    UserProfilePermissions.READ,
    MaterialityAssessmentPermissions.READ,
    DashboardPermissions.READ,
    ...COMMON_DASHBOARD_WIDGETS,
    ...MATERIALITY_ASSESSMENT_WIDGET,
    ...PROGRAM_WIDGET,
    ...ALL_ROLES_PERMISSIONS,
  ],
  [UserRole.COMPANY_DISCLOSURE_OWNER]: [
    DataCollectionPermissions.CREATE,
    DataCollectionPermissions.ASSIGN_DATA_PROVIDER,
    DataCollectionPermissions.SEND_REQUEST,
    DataCollectionPermissions.READ,
    DataCollectionPermissions.CANCEL_REQUEST,
    DataCollectionPermissions.SUBMIT_PREPARATION,
    DataCollectionPermissions.SUBMIT,
    UserProfilePermissions.READ,
    MaterialityAssessmentPermissions.READ,
    DashboardPermissions.READ,
    DashboardPermissions.WIDGET_ASSIGNED_DISCLOSURES,
    ...COMMON_DASHBOARD_WIDGETS,
    ...MATERIALITY_ASSESSMENT_WIDGET,
    ...ALL_ROLES_PERMISSIONS,
  ],
  [UserRole.COMPANY_MATERIALITY_ASSESSMENT_OWNER]: [
    MaterialityAssessmentPermissions.CREATE,
    MaterialityAssessmentPermissions.FILL_ASSESSMENT,
    MaterialityAssessmentPermissions.READ,
    MaterialityAssessmentPermissions.FINALIZE,
    UserProfilePermissions.READ,
    DashboardPermissions.READ,
    ...COMMON_DASHBOARD_WIDGETS,
    ...MATERIALITY_ASSESSMENT_WIDGET,
    ...ALL_ROLES_PERMISSIONS,
  ],
  [UserRole.COMPANY_STRATEGY_OWNER]: [
    StrategyPermissions.CREATE,
    StrategyPermissions.ASSIGN_STRATEGY_OWNER,
    StrategyPermissions.CREATE_OBJECTIVES,
    StrategyPermissions.READ,
    StrategyPermissions.PUBLISH,
    UserProfilePermissions.READ,
    DashboardPermissions.READ,
    MaterialityAssessmentPermissions.READ,
    ...COMMON_DASHBOARD_WIDGETS,
    ...MATERIALITY_ASSESSMENT_WIDGET,
    ...ALL_ROLES_PERMISSIONS,
  ],
  [UserRole.COMPANY_DATA_PROVIDER]: [
    DataCollectionPermissions.FILL_DATA,
    DataCollectionPermissions.SUBMIT_DATA,
    UserProfilePermissions.READ,
    ...COMMON_DASHBOARD_WIDGETS,
    ...ALL_ROLES_PERMISSIONS,
  ],
  [UserRole.COMPANY_SUSTAINABILITY_APPROVER]: [
    CompanyProfilePermissions.READ_AUDIT_LOGS,
    ProgramPermissions.READ,
    DataCollectionPermissions.READ,
    ReportPermissions.READ,
    MaterialityAssessmentPermissions.READ,
    StrategyPermissions.READ,
    DashboardPermissions.READ,
    ...COMMON_DASHBOARD_WIDGETS,
    ...MATERIALITY_ASSESSMENT_WIDGET,
    ...ALL_ROLES_PERMISSIONS,
  ],
  [UserRole.COMPANY_USER]: [
    UserProfilePermissions.READ,
    ...ALL_ROLES_PERMISSIONS,
  ],
  [UserRole.ESG_DATA_CONSUMER]: [...ALL_ROLES_PERMISSIONS],
  [UserRole.PLATFORM_ADMIN]: [
    PlatformAdminPermissions.READ,
    PlatformAdminPermissions.APPROVE_REQUEST_FOR_INTEREST,
    PlatformAdminPermissions.APPROVE_KYC,
    PlatformAdminPermissions.BLOCK_COMPANY,
    PlatformAdminPermissions.READ_AUDIT_LOG,
    ...ALL_ROLES_PERMISSIONS,
  ],
  [UserRole.PLATFORM_ADMIN_READ]: [...ALL_ROLES_PERMISSIONS],
  [UserRole.AUDITOR_ADMIN]: [...ALL_ROLES_PERMISSIONS],
  [UserRole.AUDITOR_USER]: [...ALL_ROLES_PERMISSIONS],
  [UserRole.KYC_USER]: [],
};

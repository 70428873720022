import { Framework, MasterDisclosureTemplate } from '@fb-ui/shared-types';

export const ESRSS19: MasterDisclosureTemplate = {
  meta: {
    id: 'S1-9',
    docType: 'tax-disclosure',
    fileName: 'esrs_401090_S1-9_2022-11-22.tax-disclosure.json',
    framework: Framework.ESRS,
    taxonomyId: 'httpsxbrlefragorgtaxonomydraftesrs20221122esrsallxsd',
    taxonomyURI:
      'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22/esrs_all.xsd',
    taxonomyPublicationDate: '2022-11-22',
    disclosureURI: 'https://xbrl.efrag.org/taxonomy/role-401090',
    disclosureName: '[401090] S1-9 Diversity indicators',
    disclosureSortId: 401090,
    namespaces: [
      {
        prefix: 'esrs',
        namespaceURI: 'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22',
      },
    ],
    dictionaries: [],
    labels: [
      {
        id: 'S1-9_labels',
        docType: 'tax-disclosure-labels',
        fileName:
          'esrs_401090_S1-9_labels_2022-11-22.tax-disclosure-labels.json',
        framework: Framework.ESRS,
        taxonomyId: 'httpsxbrlefragorgtaxonomydraftesrs20221122esrsallxsd',
        taxonomyURI:
          'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22/esrs_all.xsd',
        disclosureURI: 'https://xbrl.efrag.org/taxonomy/role-401090',
        taxonomyPublicationDate: '2022-11-22',
        libVersion: '0.1.0',
      },
    ],
    libVersion: '0.1.0',
    creationTimestamp: '2023-11-13T12:57:45+00:00',
  },
  children: [
    {
      id: 'esrs:DisclosureOfGenderDistributionAtTopManagementAndAgeDistributionAmongstEmployeesExplanatory',
      label:
        'Disclosure of gender distribution at top management and age distribution amongst employees',
      elementType: 'measure',
      dataType: 'textBlock',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
      children: [
        {
          id: 'esrs:DisclosureOfGenderDistributionAtTopManagementAndAgeDistributionAmongstEmployeesExplanatory',
          label: 'Diversity indicators',
          dataType: null,
          elementType: 'table',
          dimensions: [
            {
              id: 'esrs:GenderMember',
              label: 'Diversity indicators',
              elementType: 'dimensionExplicit',
              dataType: null,
              children: [
                {
                  id: 'esrs:MaleMember',
                  label: 'Male',
                  elementType: 'domainMember',
                  dataType: null,
                  isAbstract: false,
                },
                {
                  id: 'esrs:FemaleMember',
                  label: 'Female',
                  elementType: 'domainMember',
                  isAbstract: false,
                  dataType: null,
                },
                {
                  id: 'esrs:OtherThanFemaleAndMaleMember',
                  label: 'Other then female and male',
                  elementType: 'domainMember',
                  isAbstract: false,
                  dataType: null,
                },
                {
                  id: 'esrs:GenderNotDisclosedMember',
                  label: 'Gender not disclosed',
                  elementType: 'domainMember',
                  isAbstract: false,
                  dataType: null,
                },
                {
                  id: 'aggragate:TotalTableEmployees',
                  label: 'Total (Custom)',
                  elementType: 'domainMember',
                  isAbstract: false,
                  dataType: null,
                  isCustom: true,
                  isCalculated: true,
                },
              ],
            },
          ],
          measures: [
            {
              id: 'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel',
              label: 'Number of employees (head count) at top management level',
              elementType: 'measure',
              dataType: 'integer',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:PercentageOfEmployeesAtTopManagementLevel',
              label: 'Percentage of employees at top management level',
              elementType: 'measure',
              dataType: 'percent',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
              isCalculated: true,
            },
          ],
        },
      ],
    },
    {
      id: 'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel',
      label: 'Number of employees (head count) at top management level',
      elementType: 'measure',
      dataType: 'integer',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:PercentageOfEmployeesAtTopManagementLevel',
      label: 'Percentage of employees at top management level',
      elementType: 'measure',
      dataType: 'percent',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:NumberOfEmployeesHeadCountUnder30YearsOld',
      label: 'Number of employees (head count) under 30 years old',
      elementType: 'measure',
      dataType: 'integer',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:PercentageOfEmployeesUnder30YearsOld',
      label: 'Percentage of employees under 30 years old',
      elementType: 'measure',
      dataType: 'percent',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:NumberOfEmployeesHeadCountBetween30And50YearsOld',
      label: 'Number of employees (head count) between 30 and 50 years old',
      elementType: 'measure',
      dataType: 'integer',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:PercentageOfEmployeesBetween30And50YearsOld',
      label: 'Percentage of employees between 30 and 50 years old',
      elementType: 'measure',
      dataType: 'percent',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:NumberOfEmployeesHeadCountOver50YearsOld',
      label: 'Number of employees (head count) over 50 years old',
      elementType: 'measure',
      dataType: 'integer',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:PercentageOfEmployeesOver50YearsOld',
      label: 'Percentage of employees over 50 years old',
      elementType: 'measure',
      dataType: 'percent',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:OwnDefinitionOfTopManagementIsUsed',
      label: 'Own definition of top management is used',
      elementType: 'measure',
      dataType: 'boolean',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
      children: [
        {
          id: 'esrs:DisclosureOfOwnDefinitionOfTopManagementUsedExplanatory',
          label: 'Disclosure of own definition of top management used',
          elementType: 'measure',
          dataType: 'textBlock',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
      ],
    },
    {
      id: 'aggragete:TotalNumberOfEmployeesFlat',
      label: 'Sum of all provided data',
      elementType: 'measure',
      periodType: 'duration',
      dataType: 'integer',
      isAbstract: false,
      isNillable: true,
    },
  ],
};

/* eslint-disable no-unused-vars */

export enum BillingPermissions {
  /**
   * Permission to read billing and usage information
   */
  read = 'billing.read',
}

export enum CompanyProfilePermissions {
  /**
   * Can add users to company
   */
  ADD_USERS_TO_COMPANY = 'companyProfile.addUsersToCompany',

  /**
   * Can add supliers to company
   */
  ADD_SUPPLIERS = 'companyProfile.addSuppliers',

  /**
   * Can add suborganizations to company
   */
  ADD_SUBORGANIZATIONS = 'companyProfile.addSuborganizations',

  /**
   * Can read audit logs
   */
  READ_AUDIT_LOGS = 'companyProfile.seeAuditLogs',

  /**
   * Can add users to user groups
   */
  ADD_USERS_TO_USER_GROUP = 'companyProfile.addUsersToUserGroup',

  /**
   * Can remove users from user groups
   */
  REMOVE_USERS_FROM_USER_GROUP = 'companyProfile.removeUsersFromUserGroup',

  /**
   * Can edit KYC (Know Your Customer) information
   */
  EDIT_KYC_INFORMATION = 'companyProfile.editKYCInformation',

  /**
   * Can edit company profile information, such as contact details
   */
  EDIT_PROFILE = 'companyProfile.editProfile',

  /**
   * Can edit or update data sharing agreements
   */
  EDIT_DATA_SHARING_AGREEMENT = 'companyProfile.addOrUpdateDataSharingAgreement',
}

export enum ProgramPermissions {
  /**
   * Can create new programs
   */
  CREATE = 'program.create',
  /**
   * Can read program information
   */
  READ = 'program.read',
  /**
   * Can update existing programs
   */
  UPDATE = 'program.update',
  /**
   * Can terminate programs
   */
  TERMINATE = 'program.terminate',
}

export enum DataCollectionPermissions {
  /**
   * Can create new data collection requests
   */
  CREATE = 'dataCollection.create',
  /**
   * Can send requests to the disclosure owner
   */
  SEND_REQUEST_TO_DISCLOSURE_OWNER = 'dataCollection.sendRequestToDisclosureOwner',
  /**
   * Can assign a data provider to a request
   */
  ASSIGN_DATA_PROVIDER = 'dataCollection.assignDataProvider',
  /**
   * Can send data collection requests
   */
  SEND_REQUEST = 'dataCollection.sendRequest',
  /**
   * Can read data collection information
   */
  READ = 'dataCollection.read',
  /**
   * Can cancel data collection requests
   */
  CANCEL_REQUEST = 'dataCollection.cancelRequest',
  /**
   * Can submit preparation for data collection
   */
  SUBMIT_PREPARATION = 'dataCollection.submitPreparation',
  /**
   * Can submit data collection requests
   */
  SUBMIT = 'dataCollection.submit',
  /**
   * Can accept or reject data collection requests
   */
  ACCEPT_REJECT_REQUEST = 'dataCollection.acceptRejectRequest',
  /**
   * Can fill in data for data collection
   */
  FILL_DATA = 'dataCollection.fillInData',
  /**
   * Can submit data for a request
   */
  SUBMIT_DATA = 'dataCollection.submitData',
}

export enum ReportPermissions {
  /**
   * Can create new reports
   */
  CREATE = 'report.create',
  /**
   * Can read report information
   */
  READ = 'report.read',
  /**
   * Can add disclosures to reports
   */
  ADD_DISCLOSURES = 'report.addDisclosures',
  /**
   * Can send request to disclosure owner
   */
  SEND_REQUEST_TO_DISCLOSURE_OWNER = 'report.sendRequestToDisclosureOwner',
  /**
   * Can submit reports
   */
  SUBMIT = 'report.submit',
  /**
   * Can edit iXBRL (Inline eXtensible Business Reporting Language) in reports
   */
  EDIT_IXBRL = 'report.editIXBRL',
  /**
   * Can report on resource consumption
   */
  REPORT_CONSUMPTION = 'report.reportConsumption',
}

export enum MaterialityAssessmentPermissions {
  /**
   * Can create new materiality assessments
   */
  CREATE = 'materialityAssessment.create',
  /**
   * Can fill in materiality assessments
   */
  FILL_ASSESSMENT = 'materialityAssessment.fillAssessment',
  /**
   * Can read materiality assessment information
   */
  READ = 'materialityAssessment.read',
  /**
   * Can finalize materiality assessments
   */
  FINALIZE = 'materialityAssessment.finalize',
}

export enum StrategyPermissions {
  /**
   * Can create a new vision within a strategy
   */
  CREATE_VISION = 'strategy.createVision',
  /**
   * Can create new strategies
   */
  CREATE = 'strategy.create',
  /**
   * Can assign an owner to a strategy
   */
  ASSIGN_STRATEGY_OWNER = 'strategy.assignStrategyOwner',
  /**
   * Can create objectives within a strategy
   */
  CREATE_OBJECTIVES = 'strategy.createObjectives',
  /**
   * Can read strategy details
   */
  READ = 'strategy.read',
  /**
   * Can publish the strategy
   */
  PUBLISH = 'strategy.publish',
  /**
   * Can link disclosures to the strategy
   */
  LINK_DISCLOSURES = 'strategy.linkDisclosures',
}

export enum AnalyticsPermissions {
  /**
   * Can read analytics for platform administrators
   */
  READ_ADMIN_ANALYTICS = 'analytics.readAdminAnalytics',
  /**
   * Can read analytics for regular users
   */
  READ_USER_ANALYTICS = 'analytics.readUserAnalytics',
  /**
   * Can read analytics for company administrators
   */
  READ_COMPANY_ANALYTICS = 'analytics.readCompanyAnalytics',
}

export enum UserProfilePermissions {
  /**
   * Can create user profiles
   */
  CREATE = 'userProfile.create',
  /**
   * Can read user profiles
   */
  READ = 'userProfile.read',
  /**
   * Can edit own user profile
   */
  EDIT_OWN = 'userProfile.editOwn',
  /**
   * Can accept updates to user profiles
   */
  ACCEPT_UPDATE = 'userProfile.acceptUpdate',
  /**
   * Can delete user profiles
   */
  DELETE = 'userProfile.delete',
}

export enum EcosystemKYCPermissions {
  /**
   * Can add organizations to the ecosystem KYC
   */
  ADD_TO_ORGANIZATION = 'ecosystemKYC.addToOrganization',
  /**
   * Can add entities to the value chain in ecosystem KYC
   */
  ADD_TO_VALUE_CHAIN = 'ecosystemKYC.addToValueChain',
}

export enum ChatbotPermissions {
  /**
   * Permission to use the chatbot feature
   */
  USE = 'chatbot.use',
}

export enum PlatformAdminPermissions {
  /**
   * Can read platform administration details
   */
  READ = 'platformAdmin.read',
  /**
   * Can approve requests for interest
   */
  APPROVE_REQUEST_FOR_INTEREST = 'platformAdmin.approveRequestForInterest',
  /**
   * Can approve KYC requests
   */
  APPROVE_KYC = 'platformAdmin.approveKYC',
  /**
   * Can block a company on the platform
   */
  BLOCK_COMPANY = 'platformAdmin.blockCompany',
  /**
   * Can read platform audit logs
   */
  READ_AUDIT_LOG = 'platformAdmin.readAuditLog',
}

export enum DashboardPermissions {
  /**
   * Can read information on the main dashboard
   */
  READ = 'mainDashboard.read',

  /**
   * Permission to configure the profile of the user's own company on the dashboard
   */
  WIDGET_CONFIGURE_OWN_COMPANY_PROFILE_INTRO = 'mainDashboard.widgetConfigureOwnCompanyProfileIntro',

  /**
   * Permission for the widget showing connected companies of the user's own company
   */
  WIDGET_OWN_CONNECTED_COMPANY = 'mainDashboard.widgetOwnConnectedCompany',

  /**
   * Permission for the widget related to materiality assessment connections of the user's company
   */
  WIDGET_OWN_CONNECTED_ASSESSMENT = 'mainDashboard.widgetOwnMaterialityAssessment',

  /**
   * Permission for the widget displaying team members of the user's company
   */
  WIDGET_OWN_TEAM_MEMBERS = 'mainDashboard.widgetOwnTeamMembers',

  /**
   * Permission for the widget showing the count of ongoing programs for the user's company
   */
  WIDGET_OWN_PROGRAMS_ONGOING_COUNTER = 'mainDashboard.widgetOwnProgramsOngoingCounter',

  /**
   * Permission for the widget displaying the count of finished programs for the user's company
   */
  WIDGET_OWN_PROGRAMS_FINISHED_COUNTER = 'mainDashboard.widgetOwnProgramsFinishedCounter',

  /**
   * Permission for the widget to list assigned disclosures
   */
  WIDGET_ASSIGNED_DISCLOSURES = 'mainDashboard.widgetAssignDisclosure',

  /**
   * Permission for the introductory widget about certifications
   */
  WIDGET_CERTIFICATION_INTRO = 'mainDashboard.widgetCertificationIntro',
}

export enum NotificationPermissions {
  /**
   * Can read user notifications
   */
  READ = 'notifications.read',
}

export enum SupportedFramework {
  /**
   * Can use GRI Framework
   */
  GRI = 'supportedFramework.GRI',
  /**
   * Can use ESRS Framework
   */
  ESRS = 'supportedFramework.ESRS',
}

export type AppPermission =
  | BillingPermissions
  | CompanyProfilePermissions
  | ProgramPermissions
  | DataCollectionPermissions
  | ReportPermissions
  | MaterialityAssessmentPermissions
  | StrategyPermissions
  | AnalyticsPermissions
  | UserProfilePermissions
  | EcosystemKYCPermissions
  | ChatbotPermissions
  | PlatformAdminPermissions
  | DashboardPermissions
  | SupportedFramework
  | NotificationPermissions;

import EsgCard from '@esg/shared/components/EsgCard/EsgCard';

export interface PromoFeatureCardProps {
  title: string;
  info: string;
}

function PromoFeatureCard({
  vm: { title, info },
  background,
  cardColor,
  className = '',
  innerClassName = '',
}: {
  vm: PromoFeatureCardProps;
  background?: string;
  cardColor?: string;
  className?: string;
  innerClassName?: string;
}) {
  return (
    <EsgCard
      className={`mt-5 !p-0 ${className}`}
      variant="BoxShadow"
      color={cardColor || 'bg-supportive-7'}
    >
      <div
        className={`sm:flex min-h-[250px] justify-between ${innerClassName}`}
      >
        <div className={`py-10 px-5 basis-1/2 2xl:basis-2/3`}>
          <h2 className="text-body-text-large font-bold">{title}</h2>
          <div className="flex justify-start items-start gap-2 text-secondary mt-5">
            <div className="text-caption">{info}</div>
          </div>
        </div>
        {background && (
          <div className={`basis-1/2 2xl:basis-2/3 min-h-[250px]`}>
            <img
              src={background}
              alt="tutorial Card"
              className="h-full object-cover w-full object-left"
            />
          </div>
        )}
      </div>
    </EsgCard>
  );
}

export default PromoFeatureCard;

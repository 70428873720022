/* eslint-disable no-unused-vars */
import { StateCreator } from 'zustand';

import {
  TutorialCardConfig,
  TutorialCardEnum,
} from '@esg/shared/components/TutorialCards/TutorialCards.types';

export interface tutorialCardState {
  tutorialCard: TutorialCardConfig;
  setToggleCard: (cardId: TutorialCardEnum) => void;
}

export const tutorialCardSlice: StateCreator<tutorialCardState> = (set) => ({
  tutorialCard: {
    [TutorialCardEnum.WELCOME_ESG]: {
      isEnabled: false,
    },
    [TutorialCardEnum.SUBORGANIZATION_INFO]: {
      isEnabled: false,
    },
  },
  setToggleCard: (cardId) =>
    set((state) => {
      const updatedCards = {
        ...state.tutorialCard,
        [cardId]: { isEnabled: true },
      };
      return { tutorialCard: updatedCards };
    }),
});

import { GrSecure } from 'react-icons/gr';

import { useTranslation } from '@esg/i18n';

import PromoContent from '../components/PromoContent/PromoContent';
import PromoFeatureCard from '../components/PromoFeatureCard/PromoFeatureCard';
import PromoIntro from '../components/PromoIntro/PromoIntro';

function AuditLogPromoView({ isOpened }: { isOpened: boolean }) {
  const { t } = useTranslation('promo-audit-log');

  if (!isOpened) {
    return (
      <article className="flex flex-col justify-center items-center mt-8 text-caption text-secondary">
        <div>{t('underConstruction')}</div>
        <GrSecure className="text-[120px] mt-14 opacity-10" />
      </article>
    );
  }

  return (
    <article className="overflow-auto">
      <PromoIntro
        actionLink={'/my-sustainability'}
        text={t('intro')}
      ></PromoIntro>
      <PromoContent>
        <PromoFeatureCard
          vm={{
            title: t('logOverview.title'),
            info: t('logOverview.info'),
          }}
          background="/images/ilustrations/happy-green-girl.svg"
          cardColor="bg-supportive-2"
        />
        <PromoFeatureCard
          vm={{
            title: t('securityMonitoring.title'),
            info: t('securityMonitoring.info'),
          }}
          background="/images/ilustrations/buildings.svg"
          cardColor="bg-supportive-1"
        />
      </PromoContent>
    </article>
  );
}

export default AuditLogPromoView;

import FlagProvider from '@unleash/proxy-client-react';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { AuthProvider } from 'react-oidc-context';

import { pathsWithoutAppShell } from '@esg/config/app-paths.config';
import oidcConfig from '@esg/config/auth.config';
import config from '@esg/config/feature-flag.config';
import ErrorBoundryHandler from '@esg/shared/components/ErrorBoundryHandler/ErrorBoundryHandler';
import ChatBot from '@esg/views/ChatBot/ChatBot';

import Auth from '../../../core/auth/Auth';
import UserStore from '../../../core/UserStore/UserStore';
import AppShellLayout from '../../../shared/components/AppShellLayout/AppShellLayout';

export const AuthProvidersAndLayout = function ({
  children,
}: {
  children: ReactNode;
}) {
  const router = useRouter();
  const { asPath } = router;

  const [authConfig, setAuthConfig] = useState<object>();
  // checks whether AppShell should be rendered or not
  const renderAppShell = useMemo(
    () => !pathsWithoutAppShell.some((path) => asPath.match(path)),
    [asPath],
  );

  useEffect(() => {
    setAuthConfig(oidcConfig);
  }, []);

  return authConfig ? (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <FlagProvider config={config}>
        <AuthProvider {...authConfig}>
          <Auth>
            <UserStore>
              {renderAppShell ? (
                <>
                  <ChatBot />
                  <ErrorBoundryHandler>
                    <AppShellLayout>{children}</AppShellLayout>
                  </ErrorBoundryHandler>
                </>
              ) : (
                <ErrorBoundryHandler>{children}</ErrorBoundryHandler>
              )}
            </UserStore>
          </Auth>
        </AuthProvider>
      </FlagProvider>
    </>
  ) : null;
};

import { DisclosureUiConfig, Framework } from '@fb-ui/shared-types';

export const ESRSS19UI: DisclosureUiConfig = {
  meta: {
    templateId: 'S1-9',
    framework: Framework.ESRS,
    version: 1,
  },
  data: {
    'esrs:DisclosureOfGenderDistributionAtTopManagementAndAgeDistributionAmongstEmployeesExplanatory':
      {
        table: { splitBy: 'dimensions', columnType: 'measures' },
      },
    'esrs:GenderMember$$aggragate:TotalTableEmployees': {
      validators: [
        { id: 'isInRange', value: { min: 0 } },
        { id: 'isNotEmpty', value: true },
      ],
      formula:
        'SUM(esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:GenderMember$$esrs:MaleMember,esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:GenderMember$$esrs:FemaleMember,esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:GenderMember$$esrs:OtherThanFemaleAndMaleMember,esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:GenderMember$$esrs:GenderNotDisclosedMember)',
    },
    'esrs:PercentageOfEmployeesAtTopManagementLevel': {
      formula:
        'PERCENT(esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:GenderMember$$*,esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:GenderMember$$aggragate:TotalTableEmployees)',
    },
    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel': {
      validators: [{ id: 'isInRange', value: { min: 0 } }],
    },
    'esrs:PercentageOfEmployeesUnder30YearsOld': {
      formula:
        'PERCENT(esrs:NumberOfEmployeesHeadCountUnder30YearsOld,aggragete:TotalNumberOfEmployeesFlat)',
    },
    'esrs:PercentageOfEmployeesBetween30And50YearsOld': {
      formula:
        'PERCENT(esrs:NumberOfEmployeesHeadCountBetween30And50YearsOld,aggragete:TotalNumberOfEmployeesFlat)',
    },
    'esrs:NumberOfEmployeesHeadCountOver50YearsOld': {
      validators: [{ id: 'isInRange', value: { min: 0 } }],
    },
    'esrs:PercentageOfEmployeesOver50YearsOld': {
      formula:
        'PERCENT(esrs:NumberOfEmployeesHeadCountOver50YearsOld,aggragete:TotalNumberOfEmployeesFlat)',
    },

    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:GenderMember$$esrs:MaleMember':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },
    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:GenderMember$$esrs:FemaleMember':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },
    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:GenderMember$$esrs:GenderNotDisclosedMember':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },

    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:GenderMember$$aggragate:TotalTableEmployees':
      {
        formula:
          'SUM(esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:GenderMember$$esrs:MaleMember,esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:GenderMember$$esrs:FemaleMember,esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:GenderMember$$esrs:OtherThanFemaleAndMaleMember,esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:GenderMember$$esrs:GenderNotDisclosedMember)',
      },
    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:GenderMember$$esrs:OtherThanFemaleAndMaleMember':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },
    'esrs:PercentageOfEmployeesAtTopManagementLevel$$esrs:GenderMember$$aggragate:TotalTableEmployees':
      {
        // formula: 'TEXT(100)',
        formula:
          'SUM(esrs:PercentageOfEmployeesAtTopManagementLevel$$esrs:GenderMember$$esrs:MaleMember,esrs:PercentageOfEmployeesAtTopManagementLevel$$esrs:GenderMember$$esrs:FemaleMember,esrs:PercentageOfEmployeesAtTopManagementLevel$$esrs:GenderMember$$esrs:OtherThanFemaleAndMaleMember,esrs:PercentageOfEmployeesAtTopManagementLevel$$esrs:GenderMember$$esrs:GenderNotDisclosedMember)',
      },
    'aggragate:TotalTableEmployees': {
      label: 'Total',
      desc: 'Custom description: sum of employees by gender',
    },

    'esrs:PercentageOfEmployeesBetween30And50YearsOld$$esrs:PercentageOfEmployeesBetween30And50YearsOld':
      {
        formula:
          'PERCENT(esrs:NumberOfEmployeesHeadCountBetween30And50YearsOld$$esrs:NumberOfEmployeesHeadCountBetween30And50YearsOld,aggragete:TotalNumberOfEmployeesFlat$$aggragete:TotalNumberOfEmployeesFlat)',
      },
    'esrs:PercentageOfEmployeesUnder30YearsOld$$esrs:PercentageOfEmployeesUnder30YearsOld':
      {
        formula:
          'PERCENT(esrs:NumberOfEmployeesHeadCountUnder30YearsOld$esrs:NumberOfEmployeesHeadCountUnder30YearsOld,aggragete:TotalNumberOfEmployeesFlat$$aggragete:TotalNumberOfEmployeesFlat)',
      },

    'esrs:PercentageOfEmployeesAtTopManagementLevel$$esrs:PercentageOfEmployeesAtTopManagementLevel':
      {
        formula:
          'PERCENT(esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$esrs:NumberOfEmployeesHeadCountAtTopManagementLevel,aggragete:TotalNumberOfEmployeesFlat$$aggragete:TotalNumberOfEmployeesFlat)',
      },

    'esrs:PercentageOfEmployeesOver50YearsOld$$esrs:PercentageOfEmployeesOver50YearsOld':
      {
        formula:
          'PERCENT(esrs:NumberOfEmployeesHeadCountOver50YearsOld$$esrs:NumberOfEmployeesHeadCountOver50YearsOld,aggragete:TotalNumberOfEmployeesFlat$$aggragete:TotalNumberOfEmployeesFlat)',
      },
    'aggragete:TotalNumberOfEmployeesFlat$$aggragete:TotalNumberOfEmployeesFlat':
      {
        desc: 'Custom description: sum of employees by gender',
        formula:
          'SUM(esrs:NumberOfEmployeesHeadCountUnder30YearsOld$$esrs:NumberOfEmployeesHeadCountUnder30YearsOld,esrs:NumberOfEmployeesHeadCountBetween30And50YearsOld$$esrs:NumberOfEmployeesHeadCountBetween30And50YearsOld,esrs:NumberOfEmployeesHeadCountOver50YearsOld$$esrs:NumberOfEmployeesHeadCountOver50YearsOld)',
      },
    'esrs:GenderOtherMember': {
      label: 'Other gender',
      desc: 'Custom provided other gender description',
    },
  },
};

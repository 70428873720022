/* eslint-disable no-unused-vars */

import { IUserCompanyDetails } from '@fb-ui/shared-mocks';
import { AppUser } from '@fb-ui/shared-types';
import { StateCreator } from 'zustand';

export interface UserSessionState {
  userCompany: IUserCompanyDetails | null;
  userProfile: AppUser | null;
  isSigningOut: boolean;
  setUserCompany: (company: IUserCompanyDetails) => void;
  setUserProfie: (userProfile: AppUser) => void;
  setSigningOut: (isSigningOut: boolean) => void;
}

export const userSessionSlice: StateCreator<UserSessionState> = (set) => ({
  userCompany: null,
  userProfile: null,
  isSigningOut: false,
  setUserCompany: (company) => set({ userCompany: company }),
  setUserProfie: (userProfile: AppUser) => set({ userProfile }),
  setSigningOut: (isSigningOut: boolean) => set({ isSigningOut }),
});

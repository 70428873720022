/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApolloError } from '@apollo/client';
import { BackEndError, ICompany } from '@fb-ui/shared-types';
import { useEffect, useState } from 'react';

import { useTranslation } from '@esg/i18n';

interface ErrorApiHandlerProps {
  error?: ApolloError | string;
  isTransparent?: boolean;
}

export function ErrorApiHandler({
  error,
  isTransparent = false,
}: ErrorApiHandlerProps) {
  const { t, exists } = useTranslation('global');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const convertErrorString = (errorString: string) => {
    if (errorString) {
      try {
        const errorObject: BackEndError<ICompany> = JSON.parse(errorString);
        if (exists(`error.${errorObject.code}`)) {
          setErrorMessage(t(`error.${errorObject.code}`));
        } else {
          setErrorMessage(t(`error.something`));
        }
      } catch (error) {
        setErrorMessage(t(`error.something`));
      }
    }
  };

  useEffect(() => {
    if (error && typeof error == 'string') {
      const splitErrorString = error.split('Error:').pop();
      convertErrorString(splitErrorString as string);
    } else if (error) {
      const apolloErrorObject = error as ApolloError;
      const splitErrorString = apolloErrorObject?.message.split('Error:').pop();
      convertErrorString(splitErrorString as string);
    }
  }, [error]);

  if (!error) {
    return null;
  }

  console.log(error);

  return (
    <div
      className={`mb-4 mt-4 rounded-lg px-6 py-5 text-base justify-center items-center flex flex-col ${
        !isTransparent ? ' bg-supportive-3' : ''
      }`}
      role="alert"
    >
      <div className="font-bold text-error">
        {errorMessage ? (
          <div className="font-bold  text-error text-center">
            {errorMessage}
          </div>
        ) : (
          <div className="font-bold  text-error text-center">
            {t(`error.something`)}
          </div>
        )}
      </div>
      <div className="text-caption mt-2">{t('error.refresh')}</div>
    </div>
  );
}

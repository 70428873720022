/* eslint-disable no-unused-vars */

export enum MaterialityAssessmentCriticality {
  CRITICAL = 'CRITICAL',
  SIGNIFICANT = 'SIGNIFICANT',
  IMPORTANT = 'IMPORTANT',
  INFORMATIVE = 'INFORMATIVE',
  MINIMAL = 'MINIMAL',
  NONE = 'NONE', // Additional ; To Indicate Less than 4
}

export enum MaterialityAssessmentStatus {
  ASSESSED = 'ASSESSED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum ScaleImpact {
  NONE = 'NONE',
  MINIMAL = 'MINIMAL',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  ABSOLUTE = 'ABSOLUTE',
}

export enum ScopeImpact {
  NONE = 'NONE',
  LIMITED = 'LIMITED',
  CONCENTRATED = 'CONCENTRATED',
  MEDIUM = 'MEDIUM',
  WIDESPREAD = 'WIDESPREAD',
  GLOBAL = 'GLOBAL',
}

export enum RemediabilityImpact {
  VERY_EASY_TO_REMEDY = 'VERY_EASY_TO_REMEDY',
  SHORT_TERM = 'SHORT_TERM',
  REMEDIABLE_WITH_EFFORT = 'REMEDIABLE_WITH_EFFORT',
  MID_TERM = 'MID_TERM',
  LONG_TERM = 'LONG_TERM',
  IRREVERSIBLE = 'IRREVERSIBLE',
}
// Values Not Decided
export enum FinancialImpact {
  NONE = 'NONE',
  MINIMAL = 'MINIMAL',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  ABSOLUTE = 'ABSOLUTE',
}

import classnames from 'classnames';
import React from 'react';

import { CardVariants, EsgCardProps } from './types';

const EsgCard: React.FC<EsgCardProps> = ({
  variant = CardVariants.DEFAULT,
  children,
  padding = 'p-5',
  height = 'h-auto',
  width = 'w-auto',
  color = 'bg-background',
  border = true,
  clickable = false,
  className = '',
  ...props
}) => {
  const cardClasses = classnames(
    {
      'rounded-2xl': true,
      'border border-monochrome-1': border,
      'shadow-cardShadow': variant === CardVariants.SHADOW,
      clickable: clickable,
    },
    padding,
    color,
    height,
    width,
    className,
  );

  return (
    <div className={cardClasses} {...props}>
      {children}
    </div>
  );
};

export default EsgCard;

/* eslint-disable no-unused-vars */
export enum TutorialCardEnum {
  WELCOME_ESG = 'WELCOME_ESG',
  SUBORGANIZATION_INFO = 'SUBORGANIZATION_INFO',
}

export type TutorialCardConfig = Record<
  TutorialCardEnum,
  { isEnabled: boolean }
>;

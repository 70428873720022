/**
 * This 'appPaths' object should be source of truth for all routing paths.
 *
 * SEO good practice is to use <a> tag or <Link> component for navigation.
 * Therefore, we export functions that returns string paths for such use case.
 *
 */

import { Framework } from '@fb-ui/shared-types';

export const publicAppPaths = {
  getCompanyOnboardingPath: () => '/public/company-onboarding',
};

export const appPaths = {
  getHomePagePath: () => '/auth/dashboard',
  getDashboardPagePath: () => '/auth/dashboard',
  getAnalyticsPagePath: () => '/auth/analytics',
  getMyOrganizationPagePath: () => '/auth/my-organization',
  getMyStrategyPagePath: () => '/auth/my-strategy',
  getDisclosureDetailsPagePath: (
    programId: string,
    reportId: string,
    disclosureId: string,
  ) =>
    `/auth/my-sustainability/program/${programId}/report/${reportId}/disclosure/${disclosureId}`,
  getMySustainabilityPagePath: () => '/auth/my-sustainability',
  getMySustainabilityOngoingPrograsPagePath: () =>
    '/auth/my-sustainability/#ongoing-programs',
  getMySustainabilityFinishedPrograsPagePath: () =>
    '/auth/my-sustainability/#finished-programs',
  // PROGRAMS
  getNewProgramPagePath: () => '/auth/my-sustainability/program/new',
  getNewStrategyPagePath: () => '/auth/my-sustainability/program/new-strategy',
  getNewReportPagePath: () => '/auth/my-sustainability/program/new-report',
  getReportPagePath: (programId: string, reportId: string) =>
    `/auth/my-sustainability/program/${programId}/report/${reportId}`,
  getNewDataCollectionPagePath: (companyId: string, disclosureId: string) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/new-data-collection`,
  getAddDisclosureOwner: (companyId: string, disclosureId: string) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/add-disclosure-owner`,
  getNewMaterialityAssessmentPagePath: () =>
    '/auth/materiality-assessment/new-materiality-assessment',
  getNewMaterialityAssessmentDatesPagePath: () =>
    '/auth/materiality-assessment/new-materiality-assessment-dates',
  getProgramDetailsPagePath: (programId: string) => {
    return `/auth/my-sustainability/program/${programId}`;
  },
  getSelectFrameworkForManualDisclosureSelectionPagePath: (
    companyId: string,
  ) => {
    return `/auth/data-management/${companyId}/disclosure/new/select-framework`;
  },
  getManualDisclosureSelectionPagePath: (
    companyId: string,
    framework: Framework,
  ) => {
    return `/auth/data-management/${companyId}/disclosure/new/select-framework/${framework}/add-disclosures`;
  },
  // USER PROFILE PATHS
  getProfilePagePath: () => `/auth/profile`,
  getUserAccountSettingsPagePath: () => '/auth/profile/settings',
  getDisclosureRequestPagePath: () => '/auth/profile/disclosure-request',
  getTodosPagePath: () => '/auth/my-todos',
  getAddSupplierPath: () => '/auth/my-organization/new-supplier',
  getAddSuborganizationPath: () => '/auth/my-organization/new-suborganization',
  getMaterialityAssessmentPagePath: () => '/auth/materiality-assessment',

  getDataManagementPagePath: () => '/auth/data-management',
  getDataManagementCompanyDisclosureListPagePath: (companyId: string) =>
    `/auth/data-management/${companyId}/disclosure`,
  getDataManagementAssignCoordinator: (companyId: string) =>
    `/auth/data-management/${companyId}/disclosure/assign-coordinator`,
  getDataManagementCompanyDisclosureDetailsPagePath: (
    companyId: string,
    disclosureId: string,
  ) => `/auth/data-management/${companyId}/disclosure/${disclosureId}`,
  getDataManagementCompanyDataProvidersDisclosureCollectionPagePath: (
    companyId: string,
    disclosureId: string,
    collectionId: string,
  ) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/data-providers`,
  getDataManagementCompanyReStructureDisclosureCollectionPagePath: (
    companyId: string,
    disclosureId: string,
    collectionId: string,
  ) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/re-structure`,
  getDataManagementCompanyAddProvidersDisclosureCollectionPagePath: (
    companyId: string,
    disclosureId: string,
    collectionId: string,
  ) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/add-providers`,
  getDataManagementCompanyProvideDataDisclosureCollectionPagePath: (
    companyId: string,
    disclosureId: string,
    collectionId: string,
  ) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/provide-data`,

  getDataManagementCompanyProvideDataDisclosureCollectionPackagePagePath: (
    companyId: string,
    disclosureId: string,
    collectionId: string,
    collectionPackageId: string,
  ) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/provide-data/${collectionPackageId}`,

  getMaterialityAssessmentDetailsPagePath: (assessmentId: string) =>
    `/auth/materiality-assessment/${assessmentId}`,
  getMaterialityAssessmentTopicDetailsPagePath: (
    assessmentId: string,
    topicId: string,
  ) => `/auth/materiality-assessment/${assessmentId}/topic/${topicId}`,
  getTopicsPagePath: (programId: string) =>
    `/auth/my-sustainability/program/${programId}/disclosures`,
  getMyOrganizationPageTeamMemberPath: () =>
    '/auth/my-organization/#team-members',
  getMyOrganizationPageSubOrganizationTabPath: () =>
    '/auth/my-organization#suborganizations',
  getTeamMemberDetailPagePath: (teamMemberId: string) =>
    `/auth/my-organization/team-members/${teamMemberId}`,
  getEditTeamMemberPagePath: (teamMemberId: string) =>
    `/auth/my-organization/team-members/${teamMemberId}/edit-team-member`,
  getAddNewTeamMemberPagePath: () =>
    `/auth/my-organization/team-members/add-team-member`,
  setAddNewTeamMemberSetupRole: () =>
    `/auth/my-organization/team-members/add-team-member/setup-roles`,
};

export const pathsWithoutAppShell = [
  'program/new',
  'new-materiality-assessment',
  'new-supplier',
  'new-suborganization',
  'add-disclosures',
  'select-framework',
  'new-data-collection',
  'data-providers',
  '/auth/assign-coordinator',
  'add-disclosure-owner',
  'edit-team-member',
  'add-team-member',
];

/* eslint-disable no-unused-vars */
import { StateCreator } from 'zustand';

import { IAddTeamMemberForm } from '@esg/views/AddNewTeamMemberView/AddNewTeamMember.types';

export interface MemberInviteState {
  memberInviteFormData: IAddTeamMemberForm;
  setMemberInviteFormData: (formData: IAddTeamMemberForm) => void;
  resetMemberInviteFormData: () => void;
}

export const memberInviteSlice: StateCreator<MemberInviteState> = (set) => ({
  memberInviteFormData: {
    firstName: '',
    lastName: '',
    email: '',
  },
  setMemberInviteFormData: (memberInviteFormData) =>
    set({ memberInviteFormData }),
  resetMemberInviteFormData: () =>
    set({ memberInviteFormData: { firstName: '', lastName: '', email: '' } }),
});

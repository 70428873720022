import { DisclosureUiConfig, Framework } from '@fb-ui/shared-types';

export const ESRSS16UI: DisclosureUiConfig = {
  meta: {
    templateId: 'S1-6',
    framework: Framework.ESRS,
    version: 1,
  },
  data: {
    'esrs:CharacteristicsOfUndertakingsEmployeesNumberOfEmployeesByGenderTable':
      {
        table: {
          columnType: 'measures',
        },
      },
  },
};

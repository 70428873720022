import { IUserCompanyDetails } from '@fb-ui/shared-mocks';
import { gql } from 'graphql-tag';

export interface CompanyDetailsResponse {
  company: IUserCompanyDetails;
}

export const FETCH_COMPANY_DETAILS = gql`
  query Company($id: String!) {
    company(id: $id) {
      bank {
        accountNumber
        address
        branchName
        email
        name
        managerName
        phone
        routingNumber
        swiftCode
      }
      blockReason
      companyAdmin {
        email
        name
        title
        phone
        mobile
      }
      directors {
        name
        title
      }
      ubos {
        name
      }
      shareholders {
        name
      }
      companyCountry
      companyEmail
      companyLogo {
        hash
        name
      }
      contactUserDetails {
        id
        firstName
        lastName
      }
      companyName
      companyPhoneNumber
      companyProfile
      companyType
      companyWebsite
      contactEmail
      contactJobTitle
      contactName
      contactPhoneNumber
      createdAt
      id
      isBlocked
      isMailingAddressDifferent
      kycStatus
      mailingAddress {
        city
        country
        line1
        line2
        postal
      }
      preferences {
        unitOfMeasurement
      }
      registeredAddress {
        city
        country
        line1
        line2
        postal
      }
      registrationNumber
      rejectionReason
      resubmitKycReason
      tax
      unblockReason
      updatedAt
    }
  }
`;

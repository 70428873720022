/* eslint-disable no-unused-vars */

export enum SupplierType {
  DIRECT = 'Direct',
  INDIRECT = 'Indirect',
}

export enum ConnectionRelation {
  CHILD_ENTITY = 'Sub Organization',
  SUPPLIER = 'Supplier',
}

export enum UserType {
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  COMPANY_EMPLOYEE = 'COMPANY_EMPLOYEE',
  KYC_USER = 'KYC_USER',
}

export enum OnboardingStatus {
  INVITED = 'INVITED',
  ONBOARDED = 'ONBOARDED',
}

export const CompanyStatus = {
  KYC_STATUS_NEW: 'NEW',
  KYC_STATUS_REQUEST_REJECTED: 'REQUEST_REJECTED',
  KYC_STATUS_REQUEST_APPROVED: 'REQUEST_APPROVED',
  KYC_STATUS_SUBMITTED: 'SUBMITTED',
  KYC_STATUS_APPROVED: 'APPROVED',
  KYC_STATUS_REJECTED: 'REJECTED',
  KYC_STATUS_DRAFT: 'DRAFT',
  KYC_STATUS_EDIT: 'EDIT',
};
